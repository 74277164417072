import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { UserRolesEnum } from '~shared/model';
import { AuthGuard } from '@root/modules/user-auth/services/auth.guard';
import {
  SALES_ORDERS_ROUTE,
  ANONYMOUS_EMAIL_ROUTE,
  DISCOUNT_ROUTE,
  BUYER_SUPPORT_ROUTE,
  ACCOUNTING_ROUTE,
  SELLER_SUPPORT_ROUTE,
  DISPUTES_MANAGEMENT_ROUTE,
  DISPUTES_ROUTE,
} from '~shared/constants';
import { UnauthorizedComponent } from './modules/unauthorized/unauthorized.component';
import { NOAUTH_URL } from './modules/unauthorized/unauthorized.constants';

const routes: Routes = [
  {
    path: '',
    data: {
      roles: [
        UserRolesEnum.METROMARKETS_EMP_REFUNDS,
        UserRolesEnum.METROMARKETS_EMP_ORDERS,
        UserRolesEnum.METROMARKETS_ACC_REQUEST_REFUND,
        UserRolesEnum.METROMARKETS_EMP_ACCOUNTING,
        UserRolesEnum.METROMARKETS_SELLER_OFFICE_ADMIN,
        UserRolesEnum.METROMARKETS_SELLER_PIM_ADMIN,
        UserRolesEnum.METROMARKETS_BUYER_ADMIN,
      ],
    },
    canActivateChild: [AuthGuard],
    loadChildren: () => import('@employee-app/app.module').then(m => m.EmployeeApp),
  },
  {
    path: BUYER_SUPPORT_ROUTE,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    loadChildren: () => import('@buyer-account-app/app.module').then(m => m.BuyerAccountModule),
    data: { roles: [UserRolesEnum.METROMARKETS_BUYER_ADMIN] },
  },
  {
    path: ANONYMOUS_EMAIL_ROUTE,
    canLoad: [AuthGuard],
    loadChildren: () => import('@anonymous-email-app/app.module').then(m => m.AnonymousEmailAppModule),
    data: { roles: [UserRolesEnum.METROMARKETS_ANONYMOUS_EMAILS] },
  },
  {
    path: SALES_ORDERS_ROUTE,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    loadChildren: () => import('@sales-orders-app/app.module').then(m => m.SalesOrderModule),
    data: { roles: [UserRolesEnum.METROMARKETS_EMP_ORDERS] },
  },
  {
    path: DISCOUNT_ROUTE,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    loadChildren: () => import('@discount-app/app.module').then(m => m.DiscountAppModule),
    data: { roles: [UserRolesEnum.METROMARKETS_DISCOUNT_CODE_VIEW] },
  },
  {
    path: ACCOUNTING_ROUTE,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    loadChildren: () => import('@accounting-app/app.module').then(m => m.AccountingAppModule),
    data: { roles: [UserRolesEnum.METROMARKETS_EMP_ACCOUNTING] },
  },
  {
    path: SELLER_SUPPORT_ROUTE,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    loadChildren: () => import('@seller-support-app/app.module').then(m => m.SellerSupportAppModule),
    data: {
      roles: [UserRolesEnum.METROMARKETS_SELLER_OFFICE_ADMIN, UserRolesEnum.METROMARKETS_SELLER_PIM_ADMIN],
    },
  },
  {
    path: DISPUTES_MANAGEMENT_ROUTE,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: DISPUTES_ROUTE, pathMatch: 'full' },
      {
        path: DISPUTES_ROUTE,
        loadChildren: () => import('@disputes-app/modules/disputes/disputes.module').then(m => m.DisputesModule),
      },
    ],
    data: {
      roles: [UserRolesEnum.METROMARKETS_EMP_DISPUTES],
    },
  },
  { path: NOAUTH_URL, component: UnauthorizedComponent },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }), StoreModule.forRoot({})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
