import { Injectable } from '@angular/core';
import { LogLevel } from 'configcat-common';
import * as configcat from 'configcat-js';
import { IConfigCatClient } from 'configcat-common/lib/ConfigCatClient';
import { environment } from '~env/environment';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
/**
 * @class ConfigcatApiService
 * @package-private
 * @description This class will create a configcat instance using the key created in the config cat dashboard
 */
export class ConfigcatApiService {
  private static client: IConfigCatClient;

  private configChange = new Subject();
  configChange$ = this.configChange.asObservable();

  listenForConfigChange() {
    this.configChange.next(void 0);
  }

  private get logger() {
    const logLevel = environment.production ? LogLevel.Off : LogLevel.Info;
    return configcat.createConsoleLogger(logLevel);
  }

  public createInstance(): any {
    if (!ConfigcatApiService.client) {
      const options = {
        logger: this.logger,
        configChanged: () => {
          this.listenForConfigChange();
        },
        pollIntervalSeconds: 15,
      };

      ConfigcatApiService.client = configcat.createClientWithAutoPoll(environment.configCatSdkKey, options);

      this.listenForConfigChange();
    }

    return ConfigcatApiService.client;
  }
}
