export enum UserRolesEnum {
  METROMARKETS_EMP_ACCOUNTING = 'METROMARKETS_EMP_ACCOUNTING',
  METROMARKETS_EMP_ORDERS = 'METROMARKETS_EMP_ORDERS',
  METROMARKETS_EMP_REFUNDS = 'METROMARKETS_EMP_REFUNDS',
  METROMARKETS_ACC_REQUEST_REFUND = 'METROMARKETS_ACC_REQUEST_REFUND',
  METROMARKETS_SELLER_OFFICE_ADMIN = 'METROMARKETS_SELLER_OFFICE_ADMIN',
  METROMARKETS_SELLER_PIM_ADMIN = 'METROMARKETS_SELLER_PIM_ADMIN',
  METROMARKETS_SELLER_PIM_PDM_SUPER_ADMIN = 'METROMARKETS_SELLER_PIM_PDM_SUPER_ADMIN',
  METROMARKETS_EMP_FRAUD_LIST = 'METROMARKETS_EMP_FRAUD_LIST',
  METROMARKETS_ANONYMOUS_EMAILS = 'METROMARKETS_ANONYMOUS_EMAILS',
  METROMARKETS_DISCOUNT_CODE_VIEW = 'METROMARKETS_DISCOUNT_CODE_VIEW',
  METROMARKETS_DISCOUNT_CODE_CREATE_LVL_1 = 'METROMARKETS_DISCOUNT_CODE_CREATE_LVL_1',
  METROMARKETS_DISCOUNT_CODE_CREATE_LVL_2 = 'METROMARKETS_DISCOUNT_CODE_CREATE_LVL_2',
  METROMARKETS_DISCOUNT_CODE_CREATE_LVL_3 = 'METROMARKETS_DISCOUNT_CODE_CREATE_LVL_3',
  METROMARKETS_DISCOUNT_CODE_EDIT = 'METROMARKETS_DISCOUNT_CODE_EDIT',
  METROMARKETS_DISCOUNT_CODE_DEACTIVATE = 'METROMARKETS_DISCOUNT_CODE_DEACTIVATE',
  METROMARKETS_BUYER_ADMIN = 'METROMARKETS_BUYER_ADMIN',
  METROMARKETS_EMP_SELLER_BALANCE = 'METROMARKETS_EMP_SELLER_BALANCE',
  METROMARKETS_EMP_DISPUTES = 'METROMARKETS_EMP_DISPUTES',
}
