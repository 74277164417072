import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { queryBuilder } from '@metromarkets/sdk-17';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { deserialize } from 'serialize-ts/dist';
import {
  ALL_ORDERS_API,
  AFTERSALES_BASIC_AUTH_PASS,
  AFTERSALES_BASIC_AUTH_USER,
  ORDER_RETURN_LABEL_API,
  ORDER_RETURN_LABEL_AVAILABLE_API,
  ORDERS_API,
  ORDERS_API_V2,
} from '@sales-orders-app/modules/orders/constants/api';
import { Order } from '@sales-orders-app/modules/orders/models/order.model';
import { PaginatedResponse, RequestParams } from '~shared/model';
import { OrderReturnLabelAvailable } from '@sales-orders-app/modules/orders/models/order-return-label-available.model';
import { FeatureFlagService } from '~core/modules/feature-flag';
import { FeatureToggleEnum } from '~shared/constants';

@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  private orderAPIFeatureFlagStatus = false;

  constructor(private http: HttpClient, private featureFlagService: FeatureFlagService) {
    this.featureFlagService
      .isFeatureEnabled(FeatureToggleEnum.FF_TEMP_GET_ORDERS_V2)
      .subscribe(res => (this.orderAPIFeatureFlagStatus = res));
  }

  getOrders(requestParams: RequestParams): Observable<PaginatedResponse<Order>> {
    const params = queryBuilder.toParams(requestParams) as HttpParams;

    if (this.orderAPIFeatureFlagStatus) {
      return this.http.get<PaginatedResponse<Order>>(ORDERS_API_V2, { params }).pipe(
        map(response => ({
          totalCount: response.totalCount,
          items: response.items.map(i => deserialize(i, Order)),
        })),
      );
    }

    return this.http.get<PaginatedResponse<Order>>(ORDERS_API, { params }).pipe(
      map(response => ({
        totalCount: response.totalCount,
        items: response.items.map(i => deserialize(i, Order)),
      })),
    );
  }

  getAllOrders(requestParams: RequestParams, totalCount: number): Observable<Blob> {
    const params = queryBuilder.toParams(
      queryBuilder.toParams({
        ...requestParams,
        limit: totalCount,
        responseType: 'blob',
        observe: 'response',
      }),
    ) as HttpParams;

    return this.http.get<Blob>(ALL_ORDERS_API, { params }).pipe(map(response => response));
  }

  getReturnLabel(orderNumber: string): Observable<HttpResponse<any>> {
    return this.http.post(
      ORDER_RETURN_LABEL_API,
      { orderNumber },
      { headers: this.getReturnLabelRequestHeader(), observe: 'response', responseType: 'blob' },
    );
  }

  isReturnLabelAvailable(orderNumber: string): Observable<boolean> {
    return this.http
      .post<OrderReturnLabelAvailable>(
        ORDER_RETURN_LABEL_AVAILABLE_API,
        { orderNumber },
        { headers: this.getReturnLabelRequestHeader() },
      )
      .pipe(map(response => response.available));
  }

  private getReturnLabelRequestHeader(): HttpHeaders {
    const base64 = btoa(`${AFTERSALES_BASIC_AUTH_USER}:${AFTERSALES_BASIC_AUTH_PASS}`);
    return new HttpHeaders({
      Authorization: `Basic ${base64}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });
  }
}
