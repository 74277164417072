import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ORDERS_TAB_SELLER_INFO_API } from '@sales-orders-app/modules/orders/constants/api';
import { TabsSellerInfo } from '@sales-orders-app/modules/orders/models/tabs-seller-info.model';
import { OrderDetails } from '@sales-orders-app/modules/orders/models';
import { map } from 'rxjs/operators';
import { environment } from "~env/environment";

@Injectable({
  providedIn: 'root',
})
export class TabsSellerInfoService {
  constructor(private http: HttpClient) {
  }

  getTabsSellerInfo(order: OrderDetails): Observable<TabsSellerInfo[]> {
    return this.http
      .get<TabsSellerInfo[]>(`${ORDERS_TAB_SELLER_INFO_API}?filter[orderNumber]=${order.orderNumber}`, {
        headers: this.requestHeader(),
      })
      .pipe(
        map(sellerInfo => {
          const data = sellerInfo['items'].map(response => {
            const sellerAddress = response.addresses.filter(x => x.type === 'Default')[0];
            const contactPersons = response.contactPersons[0];
            const {addressLine1, addressLine2, city, country, postalCode} = sellerAddress;
            const address = `${addressLine1 ? `${addressLine1}, ` : ''}` +
              `${addressLine2 ? `${addressLine2}, ` : ''}` +
              `${city ? `${city}, ` : ''}` +
              `${country ? `${country}, ` : ''}` +
              `${postalCode ? `${postalCode}` : ''}`;
            return {
              organizationName: response?.name,
              firstName: contactPersons?.firstName,
              lastName: contactPersons?.lastName,
              phone: contactPersons?.phone,
              email: contactPersons?.email,
              address: address || 'N/A',
              shopName: response?.shopName,
            } as TabsSellerInfo;
          });
          return data as TabsSellerInfo[];
        }),
      );
  }

  private requestHeader(): HttpHeaders {
    const base64 = btoa(`${environment.svcAftersalesBasicAuthUser}:${environment.svcAftersalesBasicAuthPass}`);
    return new HttpHeaders({
      Authorization: `Basic ${base64}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });
  }
}
