import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActiveFeaturesConfigcatService, ConfigcatApiService } from '~shared/modules/active-features/services';

@NgModule({
  imports: [CommonModule],
})
export class ActiveFeaturesModule {
  static forRoot(): ModuleWithProviders<ActiveFeaturesModule> {
    return {
      ngModule: ActiveFeaturesModule,
      providers: [ActiveFeaturesConfigcatService, ConfigcatApiService],
    };
  }
}
