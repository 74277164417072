import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { User } from 'configcat-common/lib/RolloutEvaluator';

import { ConfigcatApiService } from './configcat-api.service';
import { environment } from '~env/environment';

@Injectable({
  providedIn: 'root',
})
/**
 * @class ActiveFeaturesService
 * @package-private
 * @description This class will check the feature flag status
 * defined in config cat dashboard and return the boolean response
 */
export class ActiveFeaturesConfigcatService {
  private readonly overrideSuffix = '_override';
  private envFeatureConfig: { [key: string]: number };

  constructor(private configCatService: ConfigcatApiService) {
    this.envFeatureConfig = Object.entries(environment.featureConfig).reduce(
      (acc, [key, value]) => ({ ...acc, [key.toLowerCase()]: value }),
      {},
    );
  }

  isFeatureEnabled(featureName: string, defaultValue?: any, user?: User): Observable<boolean> {
    const envFFName = featureName.toLowerCase();
    const overriddenFFName = `${envFFName}${this.overrideSuffix}`;
    const overriddenFFValue = this.envFeatureConfig[overriddenFFName];

    if (overriddenFFValue !== undefined) {
      return of(Boolean(overriddenFFValue));
    }

    try {
      const configCatClient = this.configCatService.createInstance();
      return new Observable<boolean>(observer =>
        configCatClient
          .getValueAsync(featureName, defaultValue, user)
          .then(featureFlagValue => {
            if (featureFlagValue !== undefined) {
              observer.next(featureFlagValue);
              return;
            }

            const envFFValue = this.envFeatureConfig[envFFName];
            if (envFFValue !== undefined) {
              observer.next(Boolean(envFFValue));
              return;
            }

            observer.next(defaultValue);
          })
          .catch(err => {
            console.error(err);
            observer.next(false);
          }),
      );
    } catch (ex) {
      return of(false);
    }
  }
}
